<template>
<div class="footer">
  <hr>
  <div class="footer-content">
    <div>
      <template v-if="$i18n.locale === 'fr'">
        <p>Doctorant</p>
        <p><a href="http://iihm.imag.fr/">Équipe IIHM</a></p>
        <p>(+33)4 57 42 16 41</p>
        <p>E-mail: adrien.chaffangeon@univ-grenoble-alpes.fr</p>
      </template>
      <template v-else>
        <p>PhD</p>
        <p><a href="https://www.bht-berlin.de/en/">Berlin university of applied sciences (BHT)</a></p>
        <p v-if="false">(+33)4 57 42 16 41</p>
        <p>E-mail: chaffangeon.adrien@gmail.com</p>
      </template>
    </div>
    <div class="flex-center">
      <ul class="socials">
        <li><a href="https://www.linkedin.com/in/chaffty" target="_blank"><unicon name="linkedin-alt" /></a></li>
        <li><a href="https://scholar.google.com/citations?user=2lQQmFwAAAAJ&hl=en&oi=ao" target="_blank"><unicon name="graduation-cap" /></a></li>
      </ul>
      <LangSelector v-if="false"/>
    </div>
    <div class="text-align-right">
      <template v-if="$i18n.locale === 'fr'">
        <h3>Université Grenoble Alpes:</h3>
        <p>Laboratoire LIG, Bâtiment IMAG (Bureau 360) </p>
        <p>700 avenue Centrale</p>
        <p>38401 Saint Martin d’Hères</p>
        <p>Isère, France, Terre, Voie Lactée, Univers</p>
      </template>
      <template v-else>
        <h3>Berlin university of applied sciences (BHT)</h3>
        <p>Haus Gauß Office B223a </p>
        <p>Luxemburger Straße 10</p>
        <p>13353 Berlin</p>
        <p>Germany, Earth, Milky Way, Universe</p>
      </template>
    </div>
  </div>
  <span></span>
</div>
</template>

<script>
 import LangSelector from '@/components/LangSelector'
 export default {
  name: 'Footer',
  components: {LangSelector }
}
</script>

<style scoped>
hr {
  width: 500px;
  border: 0;
  border-top: 2px solid var(--textlight);
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 85px;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  font-size: x-small;
  max-width: 1200px;
  box-sizing: border-box;
  padding: 30px 100px;
  margin: 10px;
}


ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.socials {
  margin: 25px 50px;
  fill: var(--accent);
}

.socials li {
  margin: 10px;
  background: var(--background);
  box-shadow: var(--light-shadow);
  padding: 10px;
  border-radius: 10px;
}

.footer-content * {
  text-align: center;
}

@media (min-width: 768px) {
  .footer-content {
    flex-direction: row;
  }

  .footer-content * {
    text-align: left;
  }

  .text-align-right h3, .text-align-right p {
    text-align: right;
  }
}
</style>

<template>
  <section id="main">
  <Round_Picture/>
  <div id="content">
    <h1>{{$t('Home.Presentation')}}</h1>
    <p>
    <template v-if="$i18n.locale === 'fr'">
      En Septembre 2020, j'ai commencé une thèse au Laboratoire d'Informatique de Grenoble, dans l'équipe <a href="http://iihm.imag.fr/">IIHM</a>. Je me focalise sur l'utilisation des micro mouvements pour l'interaction en réalité augmentée. Je suis aussi chargé de TD et enseignant vacataire à Polytech Grenoble et l'Université de Grenoble Alpes.
    </template>
    <template v-else>
      In January 2024, I began working as a postdoctoral fellow with Pr <a href="https://katrinwolf.info/">Katrin Wolf</a> at the <a href="https://www.bht-berlin.de/">Berliner Hochschule für Technik</a>.
      In December 2023, I received my PhD at the University of Grenoble Alpes in the <a href="http://iihm.imag.fr/en/">Engineering Human-Computer Interaction</a> research group. A summary of my PhD is available as a comic strip <a href="./files/BD-en.png">here</a>. My focus was on the use of microgestures to interact in augmented reality. I was teaching at Polytech Grenoble, Université Grenoble Alpes and IUT MMI Grenoble.
    </template>
    </p>

    <p>
      <template v-if="$i18n.locale === 'fr'">
        De Septembre 2019 à Septembre 2020, j'étais étudiant en master à l'ENS de Rennes en section informatique, et étudiant en master à l'Université Paris-Saclay en interaction humain machine.
      </template>
      <template v-else>
        From September 2019 to September 2020, I was a master student at ENS de Rennes in computer science and a master student at Université Paris-Saclay in human computer interaction.
      </template>
    </p>
  </div>
  <div id="news-container">
    <h1>News</h1>
    <ul>
      <li>
        1 demo paper accepted to IHM 2024.
      </li>
      <li>
        1 paper accepted to ISMAR 2023: <i>3D Selection in Mixed Reality: Designing a Two-Phase Technique To Reduce Fatigue</i>.
      </li>
      <li>
        The work of <a href="https://vincent-lambert.gitlab.io/website/">Vincent Lambert</a> on the visual representation of microgestures has been accepted to MobileHCI'23.
      </li>
      <li>
        1 paper accepted to CHI 2023: <i>µGlyph: a Microgesture Notation</i>.
      </li>
    </ul>
  </div>
  </section>
</template>

<script>

import Round_Picture from '@/components/Round_Picture'
export default {
  name: 'Home',
  components: {
    Round_Picture
  }
}
</script>

<style>
.round-img-container {
  max-width: 200px;
  max-height: 200px;
}

.round-img-container img {
  max-height: 200px;
}

@media (min-width: 768px) {
  .round-img-container {
    max-width: 400px;
    max-height: 400px;
  }

  .round-img-container img {
    max-height: 400px;
  }

  #content {
    float: left;
    position: relative;
    margin-top: 50px;
  }
}
</style>

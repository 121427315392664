<template>
  <span>
    <select v-model="$i18n.locale">
      <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang" :selected="lang === $i18n.locale">
        {{ langToName(lang) }}
      </option>
    </select>
  </span>
</template>

<script>
export default {
  name: 'LangSelector',
  components: { },
  data () {
    return {
      langs: ['en', 'fr']
    }
  },
  computed: {
    flag () {
      switch (this.$i18n.locale) {
        case 'fr':
          return 'fr'
        case 'en':
          return 'us'
        default :
          return 'us'
      }
    }
  },
  methods: {
    langToName (lang) {
      switch (lang) {
        case 'fr':
          return '🇫🇷 Français'
        case 'en':
          return '🇬🇧 English'
      }
    }
  }
}
</script>

<style scoped>
select {
  border: none;
  background: var(--foreground);
  border-radius: 20px;
  box-shadow: var(--light-shadow);
  font-size: 1.1em;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  color: var(--accent);

  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}

select:focus {
  outline:none;
}
</style>
